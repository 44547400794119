import React from 'react';
import type { Identifier, RaRecord } from 'react-admin';
import type { Translation } from '../cms-core/src/interfaces';
import FifaMenuItem from '../components/utils/FifaMenuItem';
import { en } from '../lang/en';

const fifaIndexUrl = process.env.REACT_APP_FIFA_INDEX ?? '';

/**
 * Checks if year format is valid.
 * @param {string} inputValue - Value of the input.
 * @returns {boolean} Returns boolean value if year format is valid.
 *
 * @example const isValid = isValidYearFormat(val);
 */
export const isValidYearFormat = (inputValue: string): boolean => {
  const matches = /^(20[0-9]{2})\/(20[0-9]{2})$/.exec(inputValue);
  if (matches === null) return false;
  const firstYear = parseInt(matches[1], 10);
  const secondYear = parseInt(matches[2], 10);
  return secondYear === firstYear + 1;
};

/**
 * Builds Fifa index URL.
 * @param {number | Identifier} fifaId - ID of FIFA.
 * @param {number} fifaIndexId - ID of specific entity.
 * @param {'players' | 'teams'} type - Type of entity.
 * @returns {string} Fifa index URL.
 *
 * @example const url = buildFifaIndexUrl(1, 131, 'team');
 */
export const buildFifaIndexUrl = (
  fifaId: number | Identifier,
  fifaIndexId: number,
  type: 'players' | 'teams',
): string => {
  let finalSrc: string = '';
  /* eslint-disable indent */
  switch (fifaId) {
    case 1:
      finalSrc = `${fifaIndexUrl}/FIFA08/images/${type}/10/${fifaIndexId}.png`;
      break;
    case 2:
      finalSrc = `${fifaIndexUrl}/FIFA10/images/${type}/10/${fifaIndexId}.png`;
      break;
    case 3:
      finalSrc = `${fifaIndexUrl}/FIFA11/images/${type}/10/${fifaIndexId}.png`;
      break;
    case 4:
      finalSrc = `${fifaIndexUrl}/FIFA09/images/${type}/10/${fifaIndexId}.png`;
      break;
    default:
      finalSrc = `${fifaIndexUrl}/FIFA10/images/${type}/10/${fifaIndexId}.png`;
      break;
    /* eslint-enable indent */
  }
  return finalSrc;
};

/**
 * Returns translations.
 * @param {string} locale - Name of the locale.
 * @returns {Translation} Object with translations.
 *
 * @example getLocaleTranslations('en');
 */
export const getLocaleTranslations = (locale: string): Translation => {
  return locale === 'en' ? en : {};
};

/**
 * Array of custom menu items that are propagated to core UserMenu.
 * @returns {React.FC[]} Array of items.
 */
export const customMenuItems: React.FC[] = [FifaMenuItem];

/**
 * Array of resources that are only available for admin.
 * @returns {string[]} Array of resources.
 */
export const adminResources: string[] = [
  'user',
  'role',
  'fifa',
  'manager',
  'career',
  'formation',
  'manager',
  'player',
  'position',
  'season',
  'squad',
  'team',
  'trophy',
];

/**
 * Transforms data of player entity before sending them to back-end.
 * @param {RaRecord} data - Entity data.
 * @returns {RaRecord} Transformed entity data.
 */
export const playerTransformData = (data: RaRecord): RaRecord => {
  const newData: RaRecord = {
    ...data,
    ratingPotential: data.rating_potential,
  };
  delete newData.rating_potential;
  return newData;
};

/**
 * Transforms data of team entity before sending them to back-end.
 * @param {RaRecord} data - Entity data.
 * @returns {RaRecord} Transformed entity data.
 */
export const teamTransformData = (data: RaRecord): RaRecord => {
  const newData: RaRecord = {
    ...data,
    squadId: data.squadId === 'Empty' ? null : data.squadId,
    rivalTeamId: data.rivalTeamId === undefined ? null : data.rivalTeamId,
  };
  return newData;
};
