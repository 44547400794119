import React from 'react';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import { useRecordContext, useTranslate } from 'react-admin';
import type { CustomTitleProps } from '../interfaces';

/**
 * React component that renders title for Show and Edit views.
 *
 * @prop {string} source - Name of property to use in title.
 * @prop {string} resource - Type of entity.
 * @prop {'show' | 'edit'} view - The type of view.
 */
const CustomTitle: React.FC<CustomTitleProps> = ({ source, resource, view }) => {
  const [title, setTitle] = React.useState<string>('');
  const translate = useTranslate();
  const record = useRecordContext();

  React.useEffect(() => {
    if (!record) return;
    const propValue = get(record, source, '');

    const translatedResourceName = translate(`resources.${resource}.name`, {
      _: '',
      smart_count: 1,
    });
    const translatedEdit = translate(`resources.${resource}.edit`);

    const newTitle =
      view === 'show' ? `${translatedResourceName} ${propValue}` : `${translatedEdit} ${propValue}`;

    setTitle(newTitle);
  }, [record, resource, source, translate, view]);

  return (
    <Typography variant="h6" color="#fff">
      {title}
    </Typography>
  );
};

export default CustomTitle;
