import type { Translation } from '../../cms-core/src/interfaces';
import { enCore } from '../../cms-core/src/lang/en';

export const en: Translation = {
  ...enCore,
  resources: {
    ...(enCore.resources as Record<string, string>),
    career: {
      name: 'Career |||| Careers',
      create: 'Create',
      edit: 'Edit',
      empty: 'No careers created yet',
      fields: {
        id: 'ID',
        name: 'Name',
        managerId: 'Manager',
        fifaId: 'FIFA',
      },
    },
    fifa: {
      name: 'FIFA |||| FIFAs',
      create: 'Create',
      edit: 'Edit',
      empty: 'No FIFAs created yet',
      fields: {
        id: 'ID',
        name: 'Name',
        version: 'FIFA version',
      },
    },
    formation: {
      name: 'Formation |||| Formations',
      create: 'Create',
      edit: 'Edit',
      empty: 'No formations created yet',
      fields: {
        id: 'ID',
        name: 'Name',
        defenders: 'Defenders',
        midfielders: 'Midfielders',
        attackers: 'Attackers',
        layout: 'Layout',
      },
    },
    manager: {
      name: 'Manager |||| Managers',
      create: 'Create',
      edit: 'Edit',
      empty: 'No managers created yet',
      fields: {
        id: 'ID',
        firstName: 'First name',
        lastName: 'Last name',
        fifaId: 'FIFA',
      },
    },
    player: {
      name: 'Player |||| Players',
      create: 'Create',
      edit: 'Edit',
      empty: 'No players created yet',
      fields: {
        id: 'ID',
        firstName: 'First name',
        lastName: 'Last name',
        teamId: 'Team',
        age: 'Age',
        fifaIndexId: 'FIFA Index ID',
        positionId: 'Position',
        rating: 'Rating',
        rating_potential: 'Potential rating',
        fifaId: 'FIFA',
        is_favourite: 'Favourite',
        fullName: 'Name',
        toggle_favourites: 'Favourites',
      },
    },
    position: {
      name: 'Position |||| Positions',
      create: 'Create',
      edit: 'Edit',
      empty: 'No positions created yet',
      fields: {
        id: 'ID',
        name: 'Name',
        positionGroupId: 'Position group',
      },
    },
    season: {
      name: 'Season |||| Seasons',
      create: 'Create',
      edit: 'Edit',
      empty: 'No seasons created yet',
      fields: {
        id: 'ID',
        name: 'Name',
        fifaId: 'FIFA',
      },
    },
    squad: {
      name: 'Squad |||| Squads',
      create: 'Create',
      edit: 'Edit',
      empty: 'No squads created yet',
      fields: {
        id: 'ID',
        name: 'Name',
        formationId: 'Formation',
        fifaId: 'FIFA',
        subs: 'Subs',
      },
    },
    team: {
      name: 'Team |||| Teams',
      create: 'Create',
      edit: 'Edit',
      empty: 'No teams created yet',
      fields: {
        id: 'ID',
        name: 'Name',
        budget: 'Budget (€)',
        fifaIndexId: 'FIFA Index ID',
        rivalTeamId: 'Rival team',
        squadId: 'Squad',
        fifaId: 'FIFA',
      },
    },
    trophy: {
      name: 'Trophy |||| Trophies',
      create: 'Create',
      edit: 'Edit',
      empty: 'No trophies created yet',
      fields: {
        id: 'ID',
        name: 'Name',
        fifaId: 'FIFA',
      },
    },
    managed_season: {
      name: 'Managed season',
      create: 'Create',
      edit: 'Edit',
      fields: {
        careerId: 'Career',
        seasonId: 'Season',
        teamId: 'Team',
        trophyIds: 'Trophies',
        finalPosition: 'League position',
        points: 'Points',
        squadId: 'Squad',
      },
    },
    season_trophy: {
      name: 'Season trophy',
      fields: {
        managedSeasonId: 'Trophies',
      },
    },
    notable_player: {
      name: 'Notable player |||| Notable players',
      fields: {
        id: 'ID',
        playerId: 'Player',
        managedSeasonId: 'Managed season',
        appearances: 'Appearances',
        goals: 'Goals',
        assists: 'Assists',
        yellowCards: 'YC',
        redCards: 'RC',
        cleanSheets: 'CS',
        fifaId: 'FIFA',
      },
    },
  },
  pages: {
    comingSoon: {
      title: 'Squad Builder',
      subtitle: 'The new squad builder tool for retro FIFA.',
      subtitle2: 'Coming soon.',
    },
    dashboard: {
      title: 'Squad Builder v2.0',
      subtitle: 'Squad Builder',
      newestPlayers: 'Newest players',
      topRated: 'Top rated players',
    },
  },
  actions: {
    ...(enCore.actions as Record<string, string>),
    addSeason: 'Add season',
    csvImport: 'CSV Import',
    addToFavourites: 'Add to favourites',
    removeFromFavourites: 'Remove from favourites',
    editPlayer: 'Edit player',
    editSeason: 'Edit season',
    createNotablePlayer: 'Create notable player',
    replaceBench: 'Replace with bench player',
    replaceNewPlayer: 'Replace with new player',
    selectPlayer: 'Select player',
    start: 'Start',
    select: 'Select',
    back: 'Back',
    saveSquad: 'Save squad',
    nameYourSquad: 'Name your squad',
    addSubs: 'Add subs',
    addSub: 'Add sub',
    copyPlayer: 'Copy player',
  },
  success: {
    ...(enCore.success as Record<string, string>),
    favouriteAdded: 'Player added to favourites',
    favouriteRemoved: 'Player removed from favourites',
    seasonAdded: 'Season successfully added',
    notablePlayerCreated: 'Notable player was successfully created',
    notablePlayerUpdated: 'Notable player updated',
    notablePlayerDeleted: 'Notable player deleted',
    squadSaved: 'Squad was saved',
    playerCopied: 'Player created',
    playersImported: 'Players were successfully imported',
    teamsImported: 'Teams were successfully imported',
    fifaVersionSet: 'FIFA version was successfully set',
  },
  errors: {
    ...(enCore.errors as Record<string, string>),
    duplicateSquad: 'Squad with this name already exists',
    importFailed: 'Error occured while loading file',
    squadBuilderNotAvailable: 'Squad builder is not available for selected FIFA',
    seasonFormat: 'Format should be YYYY/YYYY+1',
  },
};
