import React from 'react';
import Avatar from '@mui/material/Avatar';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';
import { type AvatarFieldProps } from '../../interfaces';
import { buildFifaIndexUrl } from '../../utils';

const AvatarField: React.FC<AvatarFieldProps> = ({ source, type, size }) => {
  const [imgSrc, setImgSrc] = React.useState<string>('');
  const record = useRecordContext();

  const fifaIndexId: number = get(record, source, 0);
  const fifaVersion: number = get(record, ['fifaId']);

  React.useEffect(() => {
    setImgSrc(buildFifaIndexUrl(fifaVersion, fifaIndexId, type));
  }, [fifaIndexId, fifaVersion, type]);

  return <Avatar alt="avatar" src={imgSrc} sx={{ width: size, height: size }} />;
};

export default AvatarField;
